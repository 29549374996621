import React from 'react';

import PropTypes from 'prop-types';

const IndexPage = ({ pageContext }) => {
  return (
    <div>
      {/* <code>
        <pre>{JSON.stringify(pageContext, null, 4)}</pre>
      </code> */}
    </div>
  );
};

IndexPage.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default IndexPage;
